import { useEffect, useState } from "react";
import useApiHelper from "../../hooks/useApiHelper";
import Item from "./NewsItem";
import Slider from "react-slick";


export default function NewsFeed({ user, actAs }) {

  const [feedData, setFeedData] = useState(undefined);
  const { get } = useApiHelper({
    headers: {
      "X-CustomerId": encodeURIComponent(user.customer.id),
      "X-ActAs": actAs
    },
  });

  const getRssFeed = async () => {

    const rssData = await get("/NewsFeed");
    setFeedData(rssData);
  };

  useEffect(() => {
    const actionAsync = async () => {
      await getRssFeed();
    };
    actionAsync();
  }, [user, actAs]);


  return (
    <div className="p-8 pb-12 bg-white rounded-md shadow row-span-3">
      <Slider
        dots={true}
        infinite={true}
        arrows={false}
        autoplay={true}
        autoplaySpeed={20000}
        className="min-h-full"
      >
        {feedData && feedData?.channel?.item.map((x, index) =>  { 
          return <Item key={index} data={x} /> })}
      </Slider>
    </div>
  );
}
