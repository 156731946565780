import { useEffect } from "react";
import Slider from "react-slick";

import FullscreenLoading from "../../components/shared/FullscreenLoading";
import ServiceStatus from "../../components/Dashboard/ServiceStatus";
import WantTile from "../../components/Dashboard/Home/WantTile";

import usePageTitleContext from "../../contexts/PageTitleContext";
import useCurrentUserContext from "../../contexts/UserContext";
import { withCustomer } from "../../data/withCustomer";
import { useStatsDate } from "../../hooks/useStatsDate";
import { useDeviceDashboard } from "../../data/useDeviceDashboard";
import LoadingWheel from "../../components/shared/LoadingWheel";
import FavVideo from "../../components/Dashboard/Home/FavVideo";

import NewsFeedData from "../../components/NewsFeed/NewsFeed";

function AccountManagerSlide({ details }) {
  return (
    <>
      <div className="w-full prose text-ag-default mb-2">
        <h1 className="text-2xl font-medium text-ag-default">
          {details.type} Account Manager
        </h1>
      </div>
      <div className="grid gap-y-2 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
        <div className="flex flex-col">
          <label className="font-medium text-ag-default">Name</label>
          <p>{details.fullName}</p>
        </div>
        <div />
        <div className="flex flex-col">
          <label className="font-medium text-ag-default">E-Mail</label>
          <p>{details.emailAddress}</p>
        </div>
        <div className="flex flex-col items-end">
          <label className="font-medium text-ag-default">Contact Number</label>
          <p>{details.telephone}</p>
        </div>
      </div>
    </>
  );
}

function _Home({ customerLoading, customer }) {
  //Preloading dashboard data
  const { currentUser } = useCurrentUserContext();
  const { startDate, endDate } = useStatsDate();
  /* eslint-disable */
  const dateRange = {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };
  const { accountManagers } = useDeviceDashboard(customer.id, dateRange);
  /* eslint-enable */

  const { setTitle } = usePageTitleContext();
  const {
    currentUser: { favouriteTiles },
  } = useCurrentUserContext();

  useEffect(() => {
    setTitle(`Welcome Back ${currentUser.user.firstname}!`);
  }, [setTitle, currentUser.user.firstname]);

  if (customerLoading) {
    return <FullscreenLoading />;
  } else {
    return (
      <div className="grid grid-cols-1 lg:grid-cols-8 gap-4 shrink">
        <div className="w-full col-span-1 lg:col-span-8">
          <p className="text-ag-darkBlue font-semibold">Your Service Status</p>
        </div>
        <div className="md:col-span-4 2xl:col-span-3 grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="md:col-span-2">
            <ServiceStatus customer={customer} />
          </div>
          {favouriteTiles.length === 0 || favouriteTiles[0] === "" ? (
            <div className="col-span-2 row-span-2">
              <FavVideo />
            </div>
          ) : (
            favouriteTiles.map(
              (tile, i) => tile !== "" && <WantTile key={i} type={tile} />
            )
          )}
          {}
          {/* Spacing divs for grid to appear correctly */}
          {/* {Array(Math.ceil((4 - favouriteTiles.length) / 2))
            .fill("")
            .map((_, i) => (
              <div key={i} className="h-40 col-span-2" />
            ))} */}
          <div className="w-full bg-white rounded-md shadow py-4 px-6 pb-8 min-h-48 md:col-span-2">
            {accountManagers.isLoading ? (
              <div className="min-h-full flex justify-center items-center">
                <LoadingWheel />
              </div>
            ) : accountManagers.data && accountManagers.data.length ? (
              <Slider
                dots={true}
                infinite={true}
                arrows={false}
                autoplay={true}
                autoplaySpeed={20000}
              >
                {accountManagers.data.map((details, i) => (
                  <AccountManagerSlide
                    details={details}
                    key={`accountManager-${i}`}
                  />
                ))}
              </Slider>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="md:col-span-4 2xl:col-span-5 grid grid-cols-1">
          <NewsFeedData user={currentUser} actAs={currentUser.actAs} />
        </div>
      </div>
    );
  }
}

const Home = withCustomer(_Home);

export default Home;
