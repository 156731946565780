
export default function RSSItem({ data }) {

  const element = document.createElement('rssItem');
  element.innerHTML = data?.description;

  const image = element.getElementsByTagName("img");
  const snippet = element.getElementsByTagName("a");

  return (
    <div className="min-h-[620px] grid grid-cols-1 xl:grid-cols-2 gap-x-8 px-2">
      <div className="xl:col-span-2 space-y-1">
        <h1 className="text-2xl font-medium text-ag-default">
          {data?.title}
        </h1>
      </div>
      <div className="flex flex-col justify-between">
        <div className="my-4">
          <img
            alt={data?.title}
            src={image[0]?.src}
            className=""
          />
        </div>
        <p className="text-base">
          {snippet[0]?.nextSibling?.innerHTML}
        </p>
        <div className="flex flex-col space-y-1 mt-4">
          <a
            className="text-lg text-ag-lightGreen font-bold no-underline hover:cursor-pointer"
            href={snippet[0]?.href}
            target="_blank" 
            rel="noopener noreferrer"
          >
            View Article
          </a>
        </div>
      </div>
    </div>
  );
}
